import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: 'https://test-swap.vitruveo.xyz/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://test-swap.vitruveo.xyz/#/pool',
      },
      {
        label: 'Wrap',
        href: '/swap',
      },
    ],
  },
  // {
  //   label: 'Farms',
  //   icon: 'FarmIcon',
  //   href: 'https://coinbase.com',
  // },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: 'https://coinbase.com',
  // },
]

export default config
