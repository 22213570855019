import React from 'react'
// import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import Web3ContextProvider from 'components/Web3ContextProvider'
import { Provider } from 'react-redux'
import { ModalProvider } from '@pancakeswap-libs/uikit'
import { LanguageContextProvider } from 'contexts/Localisation/languageContext'
import store from './state'
import getLibrary from './utils/getLibrary'
import { ThemeContextProvider } from './ThemeContext'

// const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

const Providers: React.FC = ({ children }) => {
  return (
    // <Web3ReactProvider getLibrary={getLibrary}>
      // <Web3ProviderNetwork getLibrary={getLibrary}>
      <Web3ContextProvider>
        <Provider store={store}>
          <ThemeContextProvider>
            <LanguageContextProvider>
            <ModalProvider>{children}</ModalProvider>
            </LanguageContextProvider>
          </ThemeContextProvider>
        </Provider>
        </Web3ContextProvider>
      // </Web3ProviderNetwork>
    // </Web3ReactProvider>
  )
}

export default Providers
